import { Column, Row } from "@react-tiny-grid/core"
import * as React from "react"
import { Zoom } from "react-reveal"
import styled from "styled-components"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

const Text = styled.p`
  font-size: 1.2rem;
  font-weight: 300;
  width: 100%;
`

const Button = styled.span`
  background-color: #f9c712;
  border-radius: 12px;
  padding: 15px 35px;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  color: #1c365e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: transform 0.5s ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
    transform: scale(1.05);
  }
`

const ShadowBox = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 25px;
  background-color: ${({ color }) => color};
  margin-bottom: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const PartnersPage = () => (
  <Layout>
    <Seo title="Partners" />
    <h1 className="head">PARTNER PROGRAMS</h1>
    <br />
    <h1 className="sub-head">
      Be part of our history and help us achieve key milestones.
    </h1>
    <br />
    <br />

    <ShadowBox color="#FFFFFFCC">
      <h3>Why Partner with us?</h3>
      <br />
      <Text>
        We offer competitive agreements to help you create added value to your
        products and added revenue stream to your business.
      </Text>
    </ShadowBox>

    <Row className="h100">
      <Column>
        <Zoom>
          <ShadowBox color="#1C365E">
            <div>
              <h3 className="text-white">Eviquire Desktop - Reseller</h3>
              <br />
              <Text className="text-white text-justify">
                Our valued resellers are enjoying strong returning revenue
                stream with healthy margins and our full support including
                marketing and training.
              </Text>
            </div>
            <Button onClick={() => window.HelpWidget("open")}>
              Contact us
            </Button>
          </ShadowBox>
        </Zoom>
      </Column>

      <Column>
        <Zoom>
          <ShadowBox color="#1C365E">
            <div>
              <h3 className="text-white">
                Eviquire Cloud SaaS - White-labeling
              </h3>
              <br />
              <Text className="text-white text-justify">
                We designed a white-label partner program that allow a limited
                and selected number of partners in each country to white-label
                our saas solution. They care about marketing and we take care of
                all the technical aspects. A unique business opportunity for
                whoever is in the business of Legal or Forensic Services.
              </Text>
            </div>
            <Button onClick={() => window.HelpWidget("open")}>
              Contact us
            </Button>
          </ShadowBox>
        </Zoom>
      </Column>
    </Row>
    <br />
  </Layout>
)

export default PartnersPage
